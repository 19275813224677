.cb-slideshow,
.cb-slideshow:after {
  /* position: fixed; */
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 0;
  list-style-type: none;
  filter: alpha(opacity=80);
  opacity: 0.8;
  margin: 0;
}

#hero-container .cb-slideshow,
#hero-container .cb-slideshow:after {
  /* position: absolute; */
  width: 100%;
  height: 460px;
  top: 0px;
  left: 0px;
  z-index: 0;
  list-style-type: none;
  margin: 0;
}

.cb-slideshow li span {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  color: transparent;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: none;
  opacity: 0;
  z-index: 0;
  -webkit-backface-visibility: hidden;
  -webkit-animation: imageAnimation 36s linear infinite 0s;
  -moz-animation: imageAnimation 36s linear infinite 0s;
  -o-animation: imageAnimation 36s linear infinite 0s;
  -ms-animation: imageAnimation 36s linear infinite 0s;
  animation: imageAnimation 36s linear infinite 0s;
}

.cb-slideshow li:nth-child(1) span {
  /* background-image: url(../images/bg-1.jpg)  */
}

.cb-slideshow li:nth-child(2) span {
  /* background-image: url(../images/bg-2.jpg); */
  -webkit-animation-delay: 6s;
  -moz-animation-delay: 6s;
  -o-animation-delay: 6s;
  -ms-animation-delay: 6s;
  animation-delay: 6s;
}

.cb-slideshow li:nth-child(3) span {
  /* background-image: url(../images/bg-3.jpg); */
  -webkit-animation-delay: 12s;
  -moz-animation-delay: 12s;
  -o-animation-delay: 12s;
  -ms-animation-delay: 12s;
  animation-delay: 12s;
}

.cb-slideshow li:nth-child(4) span {
  /* background-image: url(../images/bg-4.jpg); */
  -webkit-animation-delay: 18s;
  -moz-animation-delay: 18s;
  -o-animation-delay: 18s;
  -ms-animation-delay: 18s;
  animation-delay: 18s;
}

.cb-slideshow li:nth-child(5) span {
  /* background-image: url(../images/bg-5.jpg); */
  -webkit-animation-delay: 24s;
  -moz-animation-delay: 24s;
  -o-animation-delay: 24s;
  -ms-animation-delay: 24s;
  animation-delay: 24s;
}

.cb-slideshow li:nth-child(6) span {
  /* background-image: url(../images/bg-6.jpg); */
  -webkit-animation-delay: 30s;
  -moz-animation-delay: 30s;
  -o-animation-delay: 30s;
  -ms-animation-delay: 30s;
  animation-delay: 30s;
}

.cb-slideshow li:nth-child(2) div {
  -webkit-animation-delay: 6s;
  -moz-animation-delay: 6s;
  -o-animation-delay: 6s;
  -ms-animation-delay: 6s;
  animation-delay: 6s;
}

/* Animation for the slideshow images */
@-webkit-keyframes imageAnimation {
  0% {
    opacity: 0;
    -webkit-animation-timing-function: ease-in;
  }

  8% {
    opacity: 1;
    -webkit-animation-timing-function: ease-out;
  }

  17% {
    opacity: 1;
  }

  25% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@-moz-keyframes imageAnimation {
  0% {
    opacity: 0;
    -moz-animation-timing-function: ease-in;
  }

  8% {
    opacity: 1;
    -moz-animation-timing-function: ease-out;
  }

  17% {
    opacity: 1;
  }

  25% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@-o-keyframes imageAnimation {
  0% {
    opacity: 0;
    -o-animation-timing-function: ease-in;
  }

  8% {
    opacity: 1;
    -o-animation-timing-function: ease-out;
  }

  17% {
    opacity: 1;
  }

  25% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@-ms-keyframes imageAnimation {
  0% {
    opacity: 0;
    -ms-animation-timing-function: ease-in;
  }

  8% {
    opacity: 1;
    -ms-animation-timing-function: ease-out;
  }

  17% {
    opacity: 1;
  }

  25% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes imageAnimation {
  0% {
    opacity: 0;
    animation-timing-function: ease-in;
  }

  8% {
    opacity: 1;
    animation-timing-function: ease-out;
  }

  17% {
    opacity: 1;
  }

  25% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

/* Show at least something when animations not supported */
.no-cssanimations .cb-slideshow li span {
  opacity: 1;
}

@media screen and (max-width: 767px) {

  #hero-container .cb-slideshow,
  #hero-container .cb-slideshow:after {
    height: 592px;
  }
}