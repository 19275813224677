// Cards

.card {
  & .card-table table {
    & th:first-child,
    td:first-child {
      padding-left: 25px !important;
    }
  }
}

// input-group

.input-group {
  select.form-control-sm {
    height: calc(1.8125rem + 2px)!important;
    padding: 0 15px!important;
  }

  .input-group-append {
    .btn.btn-sm {
      height: calc(1.8125rem + 2px);
    }

    select.form-control-sm {
      height: calc(1.8125rem + 2px)!important;
      padding: 0 15px!important;
    }
  }
}
