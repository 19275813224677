@import "~font-awesome/scss/font-awesome";
@import "assets/fonts/feather/icon-font.css";
@import "./assets/scss/style.scss";
@import "./assets/css/fullscreen-slider.css";
// @import "./assets/css/featured-carousel.css";


body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.todo-new {
  font-size: 20px;
  margin-bottom: 30px;
}

.todo-new button {
  font-size: 15px;
}

.todo-new input {
  margin-right: 15px;
}

.todo-item {
  font-size: 20px;
}

.todo-item span {
  width: 350px;
  text-align: left;
  display: inline-block;
}

.open-todos {
  margin-bottom: 30px;
}

.todo-item input {
  margin-left: 15px;
  margin-right: 15px;
}

.margintop10 {
  margin-top: 10px;
}

.change-name {
  font-size: 12px;
}

.custom-control {
  z-index: auto !important; 
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}

.width-20-percent {
  width: 20%;
}

input.form-control.sm,
select.form-control.sm {
  padding: 5px 10px;
}

.date-time-sm .form-control {
  padding: 5px 10px;
}

.table.sm td {
  padding: 0.75rem 0.75rem;
}

.image-container {

  position: relative;
  width: 100px;
  display: inline;
  margin-right: 10px;
}

.remove-icon {
  cursor: pointer;
  position: absolute;

  top: -28px;

  right: -10px;

  background-color: #ff0000;
  /* Change the color as per your preference */

  color: #ffffff !important;
  /* Change the color as per your preference */

  width: 20px;

  height: 20px;

  /* Centering the 'x' symbol horizontally and vertically */

  display: flex;

  justify-content: center;

  align-items: center;

  border-radius: 50%;

}

.description-ellipsis-content {
  position: relative;
  max-height: 3em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}

// Example: avatar circle styles
.avatar-circle {
  width: 52px;
  height: 52px;
  font-size: 20px;
  background: linear-gradient(45deg, #6366f1, #8b5cf6);
  position: relative;
}

// Example: status dot at the bottom-right
.status-dot {
  width: 14px;
  height: 14px;
  border-radius: 50%;
}

// Property preview area
.property-preview {
  background: #f8fafc;
  // optional subtle border
  border: 1px solid #e9ecef;
}

// Hover effect for list items
.list-group-item.hover-actions {
  transition: background-color 0.2s ease;
  &:hover {
    background-color: #f9fafb;
  }
}

* {
  print-color-adjust: exact;
}

// Override bootstrap's color-adjust with print-color-adjust
.table-responsive {
  print-color-adjust: exact;
}